import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'Globe Gamer Grounds',
  clientName: 'Globe Gamer Grounds',
  copyrightName: 'Swarmio Media',
  clientOrganizationSiteUrl: 'https://swarmio.media/',
  marketingSiteUrl: 'https://globegg.emberhub.gg',
  socialLinks: {
    facebook: 'https://www.facebook.com/globetelecom.ggwp',
    discord: 'https://discord.gg/Wf4MjxGM8z',
    email: 'mailto:info@emberhub.gg',
  },
  nav: {
    orientation: 'vertical',
  },
  currency: {
    showOnlyPoints: false,
  },
  accountSetupRoute: Routes.connectGameAccounts,
  showStreamsOnHomePage: false,
  numberOfEventsToShowOnHomePage: 6,
  numberOfServersToShowOnHomePage: 2,
  authenticationFlowType: AuthenticationFlowTypes.Carousel,
  featuredEventsCarouselType: FeaturedEventsCarouselType.Centered,
  streams: {
    headerType: 'carousel',
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  eventDiscordLink: 'https://discord.gg/bTzKfHBUB5',
  msisdn: {
    requiredness: MsisdnRequiredness.NON_VERIFIED,
  },
  subscriptions: {
    allowUpgrades: false,
    allowUnsubscribe: false,
  },
  showUpcomingMatchTimes: false,
  countryCode: 608,
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
  colors: {
    ...defaultGlobalClientConfig.colors,
    darkGrey: '#999999',
    lightGrey: '#c2c6d5',
    black: '#0f0f0f',
    white: '#f2f2f2',
    primary: '#2cc1df',
    secondary: '#0f0f0f',
    danger: 'red',
    success: '#80ff80',
    warning: '#ffcb50',
    dark: '#1a1a1a',
    light: '#c1c0c0',
  },
};

export default GlobalClientConfig;
