import Container from "react-bootstrap/esm/Container"

/**
 * Renders a basic terms and conditions page.
 */
const TermsAndConditions = () => (
    <Container>
    <div style={{marginTop: "2em"}}>
        <h1>1. Introduction</h1>
        <p>Welcome to Swarmio Media Inc. registered at 1809 Barrington Street, Suite 505, Halifax, NS. Canada B3J 3K8, (SWARMIO MEDIA INC), the operator of ember.gg and “  by Ember” affiliated websites. These Terms and Conditions of Use constitute a binding agreement (the Terms) between you and SWARMIO MEDIA INC for the usage of ember.gg or any “powered by Ember” affiliated website. (each a Party and together, the Parties), the operator of SWARMIO MEDIA INC, a limited liability company registered in Canada is a Video Gaming and Esports company.</p>
        <p>&nbsp;</p>
        <p>These Terms shall be governed by and construed in accordance with the laws of Canada (the Applicable Law).</p>
        <p>&nbsp;</p>
        <p>The Terms govern your access and use of all features, content, and other services that SWARMIO MEDIA INC own, control and make available through SWARMIO MEDIA INC’s online services, right to participate in SWARMIO MEDIA INC’s online gaming competitions and challenges (the Competition(s)), receipt of Prizes (as defined below), and any other applications, tools, products, and services that SWARMIO MEDIA INC may provide from time to time (collectively the Services).</p>
        <p>&nbsp;</p>
        <p>FOR YOUR OWN BENEFIT, YOU SHOULD READ THESE TERMS CAREFULLY BEFORE ACCEPTANCE. IN PARTICULAR, WE DRAW YOUR ATTENTION TO THE FOLLOWING KEY PROVISIONS:</p>
        <p>&nbsp;</p>
        <p>(i) The processing, storage and use of your personal data (found under Article ‎‎11 (Data Protection and Confidentiality));</p>
        <p>&nbsp;</p>
        <p>(ii) How we may choose to market using your personal data (found under Article ‎12 (Marketing)); and</p>
        <p>&nbsp;</p>
        <p>(iii) Our limitation of liability found under Article ‎16.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>2. Acceptance of Terms</h1>
        <p>2.1 By registering an account, accessing the Website and/or using the Services, or by clicking a button or checking a box indicating that you accept these Terms, you declare that you read, understood, and accepted the provisions of these Terms in full and hereby agree to comply with and be bound by them. These Terms may be amended from time to time. Therefore you are encouraged to revisit the Website in order to be updated on any amendments. Such amendments will become effective and binding on you, from publication, announcement or communication of the amendment on the Website accessible via link from the homepage. Your continued use of the Services after such notice confirms your consent to and acceptance of such amendments.</p>
        <p>&nbsp;</p>
        <p>2.2. It is important that you read and understand the provisions of these Terms that will constitute a binding agreement between you and SWARMIO MEDIA INC before acceptance in accordance with Article ‎2.1. If there is any provision that you do not understand, then please contact SWARMIO MEDIA INC before acceptance by:</p>
        <p>&nbsp;</p>
        <p>(a) email: <a href="mailto:info@ember.gg">info@ember.gg</a></p>
        <p>&nbsp;</p>
        <p>2.3. Should you have any queries, complaints, feedback, or comments you would like to communicate to SWARMIO MEDIA INC, kindly contact SWARMIO MEDIA INC on the contact details listed in Article ‎2.2.</p>
        <p>&nbsp;</p>
        <p>2.4. If you do not agree to the provisions of these Terms, you must not register an account, access, use or receive any of the Services, or participate in any Competitions.</p>
        <p>&nbsp;</p>
        <p>2.5  Your use of and access to the Website and Services is subject to any other written and duly executed contracts you may have with SWARMIO MEDIA INC in addition to these Terms and any Competition Rules (as defined below).</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>3. Eligibility</h1>
        <p>3.1. By registering an account (the Account), accessing the Website, using the Services and/or participating in Competitions, you warrant and represent to SWARMIO MEDIA INC the following:</p>
        <p>&nbsp;</p>
        <p>(a) either:</p>
        <p>&nbsp;</p>
        <p>(i) you are at least 18 years of age (or over the legal age prescribed under the Applicable Law as the case may be) and have the legal right, capacity, and power to accept the provisions of these Terms with SWARMIO MEDIA INC in accordance with the Applicable Law; or</p>
        <p>(ii) you are a legal guardian (the Legal Guardian) of a minor under the age of 18, who will register the Account, access the Website, the Services and/or participate in Competitions (Minor(s)), and have the legal right, capacity and power to accept the provisions of these Terms with SWARMIO MEDIA INC in accordance with the Applicable Law;</p>
        <p>(b) you agree at all times to abide by the provisions of these Terms which are binding and enforceable against you;</p>
        <p>&nbsp;</p>
        <p>(c) you are physically located in a jurisdiction in which participation in the Competitions and/or use of the Services are unrestricted by the laws of that jurisdiction;</p>
        <p>&nbsp;</p>
        <p>(d) you meet the eligibility requirements to register an Account, access and use the Website and Services;</p>
        <p>&nbsp;</p>
        <p>(e) you have read, understood, and accepted all of SWARMIO MEDIA INC’s published policies on the Website as updated from time to time, and you will continue to revisit the Website, from time to time, to review any updates and amendments to such policies and these Terms;</p>
        <p>&nbsp;</p>
        <p>(f) you are responsible for any activity that occurs on your Account;</p>
        <p>&nbsp;</p>
        <p>(g) you are responsible for the accuracy, correctness, validity, completeness, true and up-to-date nature of all information inputted by you onto the Website (including at the time of registering an Account and/or use of Services) and/or communicated by you to SWARMIO MEDIA INC by any means, and your agreement to maintain and promptly update such information (the User Information); and</p>
        <p>&nbsp;</p>
        <p>(h) you must  maintain control over your Account, and not disclose your Account’s username and password (the Credentials), share your Account with others or allow access to your Account and Services, and/or redemption of Prizes (as defined below) awarded to your Account other than by yourself.</p>
        <p>&nbsp;</p>
        <p>3.2. Where Article 3.1(a)‎(ii) applies:</p>
        <p>&nbsp;</p>
        <p>(a) all references to “you”, in these Terms, shall be deemed to refer to both the Legal Guardian and the Minor;</p>
        <p>&nbsp;</p>
        <p>(b) the Legal Guardian is responsible for the actions or omissions of the Minor and shall ensure the Minor’s compliance with the Terms, Applicable Law, and the Minor’s use of the Website, Services and/or participation in Competitions; and</p>
        <p>&nbsp;</p>
        <p>(c) you shall adhere and take the steps necessary required by any special registration procedures set by SWARMIO MEDIA INC for Minors’ account creation and use of Website and Services and declare the details necessary related to your Legal Guardian where required. SWARMIO MEDIA INC, reserves that right to request evidence from you and/or your declared Legal Guardian, at any time, to evidence your Legal Guardian’s agreement to the provisions of these Terms and your use and access of the Website, Services, and/or participation in any Competition. If you refrain or fail to produce such evidence promptly, SWARMIO MEDIA INC reserves the right to, with or without notice, suspend, restrict and/or terminate your Account, access and/or use of the Website and Services.</p>
        <p>&nbsp;</p>
        <p>3.3. If any User Information is untrue, inaccurate, out-of-date or incomplete, or if SWARMIO MEDIA INC has reasonable grounds to suspect that such User Information is untrue, inaccurate, out-of-date or incomplete, SWARMIO MEDIA INC has the right, at its sole discretion, to (i) request you to promptly update or correct such User Information; and/or (ii)  immediately suspend, terminate and/or delete your Account and refuse to provide current or future Services to you, including, without limitation, restricting your ability to access and/or participate in, or receive any Prizes (as defined below) due to your participation in Competitions.</p>
        <p>&nbsp;</p>
        <p>3.4. SWARMIO MEDIA INC is not obliged to verify the User Information, the identity or authority of a person using your Account or Credentials, but SWARMIO MEDIA INC may, in its sole and absolute discretion, at any time require verification of the identity of a person seeking to access or use your Account and SWARMIO MEDIA INC’s Services and SWARMIO MEDIA INC may deny access to, use of, suspend and terminate your Account if SWARMIO MEDIA INC is not satisfied with such verification.</p>
        <p>&nbsp;</p>
        <p>3.5. It is your responsibility to ensure the security of your Account, and that your Account’s Credentials are not compromised, shared with anyone, or mishandled. Any loss resulting from the unauthorized use of your Account’s Credentials is in your responsibility alone. If you suspect that an unauthorized person has become aware of your Account’s Credentials, you must change your Credentials, and immediately contact SWARMIO MEDIA INC for assistance. SWARMIO MEDIA INC will not be responsible for any unauthorized use of your Account or the redemptions awarded of Prizes (as defined below) assigned to your Account.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>4. Registration</h1>
        <p>4.1. To access and use the Services, you must first complete the registration process to create an Account, which may involve:</p>
        <p>&nbsp;</p>
        <p>(a) registering unique Credentials;</p>
        <p>&nbsp;</p>
        <p>(b) providing contact information, such as your name, phone number, email address, and delivery address;</p>
        <p>&nbsp;</p>
        <p>(c) providing one or more Payment Methods (as defined below) and billing information for Subscriptions (as defined below); and</p>
        <p>&nbsp;</p>
        <p>(d) providing SWARMIO MEDIA INC with any other information it requests on the Account registration form.</p>
        <p>&nbsp;</p>
        <p>4.2. Your Account will be active after the completion of the registration process, subject to the acknowledgement methods and any other verification methods in place and undertaken by SWARMIO MEDIA INC, from time to time, for example sending acknowledgement emails on your Account’s activation from SWARMIO MEDIA INC to you, verification of your identity via email or verification of the Payment Methods (as defined below) (the Registration Date).</p>
        <p>&nbsp;</p>
        <p>4.3. You may only register and use a single Account and may not use or access multiple Accounts.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>5. Subscriptions</h1>
        <p>5.1. For the purposes of this Article and these Terms, Payment Method(s) means a current, valid, accepted method of payment by SWARMIO MEDIA INC, as may be updated from time to time, and which may include payment through your Account with a third party (the Payment Method(s)).</p>
        <p>&nbsp;</p>
        <p>5.2. SWARMIO MEDIA INC offers a number of subscription plans for the provision of Services (the Subscription Plan(s)) for you to choose from to access and use the Website and Services and participate in any Competition.</p>
        <p>&nbsp;</p>
        <p>5.3. During the registration of your Account, SWARMIO MEDIA INC may      ask you to choose a Subscription Plan and provide one or more Payment Methods to SWARMIO MEDIA INC (the Subscription).</p>
        <p>&nbsp;</p>
        <p>5.4. The prices of the Subscription Plans you may opt for (the Subscriptions Fee(s)) are as set out on the Website.</p>
        <p>&nbsp;</p>
        <p>5.5. Your Subscription will continue until canceled      in accordance with Article ‎7.15 or deactivated in accordance with Article ‎10.3.</p>
        <p>&nbsp;</p>
        <p>5.6. You must cancel your Subscription, in accordance with Article ‎7.15, before it renews in order to avoid billing of the Subscription Fee for the next billing cycle of your chosen Payment Method as per Article ‎7.1.</p>
        <p>&nbsp;</p>
        <p>5.7. SWARMIO MEDIA INC accepts Payments via Third Party Accounts (as defined below) for Subscription Fee payments. Payments may need to be authorized by the relevant Payment Method service provider. SWARMIO MEDIA INC reserves the right to undertake extra security verification steps to ensure the security of payments undertaken on SWARMIO MEDIA INC’s Website.</p>
        <p>&nbsp;</p>
        <p>5.8. SWARMIO MEDIA INC may change its Subscription Fees for its different Subscription Plans, from time to time; however, any Subscription Fee changes will apply no earlier than 30 days following notice to you.</p>
        <p>&nbsp;</p>
        <p>5.9. SWARMIO MEDIA INC will do all that it reasonably can to ensure that all of the information you give SWARMIO MEDIA INC when paying for Services and Subscriptions is secure by using an encrypted secure payment mechanism. However, in the absence of negligence on SWARMIO MEDIA INC’s part, any failure by SWARMIO MEDIA INC to comply with these Terms or the data protection and confidentiality provisions, in accordance with Article ‎11, or breach by SWARMIO MEDIA INC of duties under the Applicable Law, SWARMIO MEDIA INC will not be legally responsible to you for any loss that you may suffer if a third party gains unauthorized access to any information that you give SWARMIO MEDIA INC.</p>
        <p>&nbsp;</p>
        <p>5.10. SWARMIO MEDIA INC reserves the right to downgrade a users’ subscription level in the event of non-payment. Payment for the new lower subscription level will be billed immediately.</p>
        <h1>  6. Ember Points</h1>
        <p>6.1. By using the Ember service, users might be awarded or purchase Ember points, a virtual currency provided to users for purchasing digital content (such as game vouchers) and physical goods (such as gamer equipment or team merchandise) on the portal’s Store.</p>
        <p>&nbsp;</p>
        <p>6.2 Points might be included in Ember subscriptions, and may differ according to the different subscription tier or billing cycle.</p>
        <p>&nbsp;</p>
        <p>6.3 Points will be credited to Ember users balances immediately upon receiving payment confirmation, except in certain cases related to specific payment methods, and in which points may take up to 7 days to be credited after successfully charging for a user’s subscription renewal.</p>
        <p>&nbsp;</p>
        <p>6.4 Different point packs are also available for user purchase on the portal’s Store section. Point packs will add onto the user’s point balance for him to use on the Store.</p>
        <p>&nbsp;</p>
        <p>6.5  SWARMIO MEDIA INC reserves the right to determine an expiration date for points sold or awarded to users on the Ember platform.</p>
        <p>&nbsp;</p>
        <p>6.6 In the event that SWARMIO MEDIA INC opts to define an expiration date for Ember points, it will provide clear information to users about such expiration date.</p>
        <p>&nbsp;</p>
        <p>6.7 SWARMIO MEDIA INC reserves the right to issue points at its discretion for marketing purposes.</p>
        <h1>7     . Billing, Payment Methods, and Cancellation</h1>
        <p>Billing Cycles</p>
        <p>&nbsp;</p>
        <p>7     .1. The Subscription Fee for your Subscription and any other charges you may incur in connection with your use of the Services, such as taxes and possible transaction fees, will be charged to your Payment Method on a  , or on any such other period in accordance with Article ‎7.2, on the Registration Date indicated on your Account page (the Payment Date).</p>
        <p>&nbsp;</p>
        <p>7     .2. The length of your billing cycle will depend on the type of Subscription that you choose when you registered your Account for the use of Service.</p>
        <p>&nbsp;</p>
        <p>7     .3. In some cases, your Payment Date may change, for example if your Payment Method has not successfully settled or when you change your Subscription Plan.</p>
        <p>&nbsp;</p>
        <p>7     .4. SWARMIO MEDIA INC may authorize your Payment Method in anticipation of your Subscription or service-related charges through various methods, including authorizing it for up to approximately one month of Services as soon as you register.</p>
        <p>&nbsp;</p>
        <p>7     .5. If you signed up for Services using your Account with a third party as a Payment Method (Payments via Third Party Account), you can find the billing information about your Subscription by visiting your account with the applicable third party.</p>
        <p>&nbsp;</p>
        <p>Payment Methods</p>
        <p>&nbsp;</p>
        <p>7     .6.To use the Paid Services, you are required to provide one or more Payment Methods.</p>
        <p>&nbsp;</p>
        <p>7     .7. You authorize SWARMIO MEDIA INC to charge any Payment Method associated with your Account in case your primary Payment Method is declined or no longer available to SWARMIO MEDIA INC for payment of the Subscription Fee.</p>
        <p>&nbsp;</p>
        <p>7     .8. You remain responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel and/or deactivate your account, SWARMIO MEDIA INC may suspend your access to the Service until SWARMIO MEDIA INC has successfully charged a valid Payment Method.</p>
        <p>&nbsp;</p>
        <p>7     .9. For some Payment Methods, the issuer may charge you certain fees, such as foreign transaction fees or other fees relating to the processing of your Payment Method. Local tax charges may vary depending on the Payment Method used. Check with your Payment Method service provider for details.</p>
        <p>&nbsp;</p>
        <p>Updating your Payment Methods</p>
        <p>&nbsp;</p>
        <p>7     .10. You may update your Payment Method by going to the “Account” page.</p>
        <p>&nbsp;</p>
        <p>7     .11. SWARMIO MEDIA INC may also update your Payment Methods using information provided by the Payment Method service providers.</p>
        <p>&nbsp;</p>
        <p>7     .12. Following any updates mentioned above, you authorize SWARMIO MEDIA INC to continue to charge the applicable and updated Payment Method(s).</p>
        <p>&nbsp;</p>
        <p>Cancellation</p>
        <p>&nbsp;</p>
        <p>7     .13. You can cancel your Subscription at any time, and you will continue to have access to the Services paid for through the end of your billing period.</p>
        <p>&nbsp;</p>
        <p>7     .14. To the extent permitted by the Applicable Law, payments are non-refundable and SWARMIO MEDIA INC does not provide refunds or credits for any partial Subscription periods.</p>
        <p>&nbsp;</p>
        <p>7     .15. To cancel, go to the “Account” page and follow the instructions for cancellation. If you cancel your Subscription, your Subscription will terminate at the end of your current billing period and any access to Services related to your Subscription will end thereafter. You may, after your Account’s cancellation and the end of your Subscription in accordance with this Article, still access the Website and use  the free Services available to you by SWARMIO MEDIA INC through your Account.</p>
        <p>&nbsp;</p>
        <p>7     .16. If you signed up to your Subscription using your account with a third party as a Payment Method and wish to cancel your Subscription, you may need to do so through such third party, for example by visiting your account with the applicable third party and turning off auto-renew, or unsubscribing from the Subscription through that third party.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>8     . Competition and Prizes</h1>
        <p>8     .1 Competitions</p>
        <p>&nbsp;</p>
        <p>(a) SWARMIO MEDIA INC provides you with the opportunity to participate in Competitions through which you may compete online against other Account holders. Each Competition is subject to its separate set of conditions and rules in addition to the provisions of these Terms (the Competition Rules) that may be amended by SWARMIO MEDIA INC, from time to time, at SWARMIO MEDIA INC’s sole discretion.</p>
        <p>&nbsp;</p>
        <p>(b) Participation in Competition may result in the receipt of prizes as may be set out in the Competition Rules (the Prize(s)).</p>
        <p>&nbsp;</p>
        <p>(c) By signing up and/or participating in Competitions, you agree to comply with the Competition Rules.</p>
        <p>&nbsp;</p>
        <p>(d) SWARMIO MEDIA INC reserves the right, in its sole and absolute discretion, to disqualify you from such Competition, refuse to award you Prizes and/or any other benefits, require the return of Prizes, and/or invalidate Competition results upon your breach or risk of breach of any Competition Rules, and/or abusive, improper, unfair, and/or potentially unlawful misconduct by yourself while using the Website and/or Services and/or any behaviour that is in any way detrimental to other Account holders, SWARMIO MEDIA INC, and/or SWARMIO MEDIA INC Entities (as defined below).  SWARMIO MEDIA INC’s decision shall be final and binding in this regard.</p>
        <p>&nbsp;</p>
        <p>(e) SWARMIO MEDIA INC reserves the right, at its sole discretion, to cancel any Competition at any given time by providing sufficient notice to Account holders.</p>
        <p>&nbsp;</p>
        <p>8     .2 Type of Prizes awarded for Competitions</p>
        <p>&nbsp;</p>
        <p>(a) Prizes awarded as per the Competition rules may consist of cash, vouchers, tickets, items, virtual digital cards, and awarded points.</p>
        <p>&nbsp;</p>
        <p>(b) Some Prizes may differ slightly from the images advertised and/or published on the Website or communicated to you by SWARMIO MEDIA INC by any means.</p>
        <p>&nbsp;</p>
        <p>(c) Points awarded may allow you to earn virtual items for use on the Website or Services in accordance with SWARMIO MEDIA INC’s point collections system in place at any given time (the Points).</p>
        <p>&nbsp;</p>
        <p>(d) Competition results and Prize awards, including guaranteed Prizes, are based on final statistics and scoring results once Competitions are completed in accordance with Competition Rules.</p>
        <p>&nbsp;</p>
        <p>(e) Prizes are only awarded after results are reviewed, graded, and announced by SWARMIO MEDIA INC in accordance with the Competitions Rules (the Decision of Award).</p>
        <p>&nbsp;</p>
        <p>(f) The Decision of an Award may take up to 30 days from the completion of the relevant Competition.</p>
        <p>&nbsp;</p>
        <p>(g) Your Account is not transferable to any other person or Account holder.</p>
        <p>&nbsp;</p>
        <p>(h) Points awarded may not be sold, traded or sublicensed (including for monetary exchange or for any other value) and are not transferable upon death or by operation of the Applicable Law.</p>
        <p>&nbsp;</p>
        <p>(i) SWARMIO MEDIA INC may, in its sole and absolute discretion, require you to execute a separate release of claims as a condition of being awarded any Prize you are entitled to.</p>
        <p>&nbsp;</p>
        <p>8     .3. Prizes supplied by third parties</p>
        <p>&nbsp;</p>
        <p>(a) Prizes, such as but not limited to, items, tickets and vouchers, are subject to availability and stocks, as they may be limited. Such Prizes are subject to applicable terms and conditions (including booking requirements, cancellation restrictions, return conditions, warranties and limitations of liability) of the Prizes’ suppliers and/or merchants.</p>
        <p>&nbsp;</p>
        <p>(b) If a Prize is faulty or damaged, the supplier’s or merchant’s warranty will apply and you must revert to the relevant supplier or merchant for support and assistance. SWARMIO MEDIA INC will not hold any responsibility for any faulty or damaged Prizes.</p>
        <p>&nbsp;</p>
        <p>(c) All conditions and warranties, whether expressed or implied and whether arising under the Applicable Law or otherwise, as to the condition, suitability, quality, fitness and/or safety of any Prize supplied are expressly excluded to the full extent permitted by the Applicable Law. Any liability that cannot be fully excluded is limited, where permitted, to replacing, repairing or crediting the value of the Prize are at SWARMIO MEDIA INC’s sole and absolute discretion.</p>
        <p>&nbsp;</p>
        <p>8.4. Prize redemption and delivery</p>
        <p>&nbsp;</p>
        <p>(a) After the Decision of Award, Prizes must be redeemed in accordance with the redemption procedure put in place by SWARMIO MEDIA INC, on its Website, at any given time, for security purposes and confirmation of the correct address of delivery (the Redemption Procedure). Additional security questions might be asked by SWARMIO MEDIA INC’s staff during the redemption procedure to ensure the identity of the Account holder and, where relevant, the delivery of awarded Prizes to the rightful Account holder.</p>
        <p>&nbsp;</p>
        <p>(b) If a Prize is not redeemed by the rightful Account holder after a year from the Decision of Award, rights of the relevant Account holder with respect to such Prize will be deemed lost.</p>
        <p>&nbsp;</p>
        <p>(c) Tangible Prizes redeemed through the Website will be delivered directly, where relevant, to the address provided as part of the Redemption Procedure within 60 days from completion of the Redemption Procedure (the Date of Redemption) by the relevant Account holder.</p>
        <p>&nbsp;</p>
        <p>(d) Should SWARMIO MEDIA INC experience any difficulty in delivering any tangible Prize to the rightful Account holder, for reasons in its control, SWARMIO MEDIA INC reserves the right to give notice to the relevant Account holder for an extension of time for the relevant Prize’s delivery up to an additional 30 working days.</p>
        <p>&nbsp;</p>
        <p>(e) Tangible Prizes which are redeemed and requested by the rightful Account holder and not claimed within six months after the Date of Redemption, will expire if the rightful Account holder is not reachable on the address provided as part of the Redemption Procedure.</p>
        <p>&nbsp;</p>
        <p>(f) SWARMIO MEDIA INC will not be responsible for any delay, loss, theft, damage, for reasons beyond its control, in the course of the Prize’s delivery or otherwise.</p>
        <p>&nbsp;</p>
        <p>(g) Once a Prize has been delivered, picked up or redeemed after the completion and in accordance with information provided in the Redemption Procedure, it cannot be returned or exchanged for any other Prize.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>9     . Platform Malfunctions and Use of Website</h1>
        <p>9     .1. Services may be changed, withdrawn, or terminated at any time, by SWARMIO MEDIA INC in its sole and absolute discretion, without notice. SWARMIO MEDIA INC will not be liable if, for any reason, all or any part of the Services is restricted or made unavailable at any time or for any period.</p>
        <p>&nbsp;</p>
        <p>9     .2. SWARMIO MEDIA INC hereby grants you a non-transferable, non-exclusive, revocable, limited license to access and use SWARMIO MEDIA INC’s Website and Services solely for the purpose of the use of Services. SWARMIO MEDIA INC may, from time to time, update or modify the Website and/or Services, release new versions of the Website and/or Services or create new modules related thereto, each of which may, at SWARMIO MEDIA INC’s sole and absolute discretion, be included within the license described above. Account holders shall not be permitted to sub-license or transfer any of Account holder’s rights hereunder including, without limitation, access to the Website and/or Services.</p>
        <p>&nbsp;</p>
        <p>9     .3. You may only use the Services from the Website for your own personal use.</p>
        <p>&nbsp;</p>
        <p>9     .4. Except as explicitly set forth herein, neither SWARMIO MEDIA INC and its parents, subsidiaries, affiliates, related companies, directors, officers, employees, agents, other representatives, partners and licensors (the SWARMIO MEDIA INC Entities), make any warranties of any kind, either expressed or implied, including, without limitation:</p>
        <p>&nbsp;</p>
        <p>(a) warranties of merchantability or fitness for a particular purpose related to the use of Website, Services, Competitions, and awarded Prizes;</p>
        <p>&nbsp;</p>
        <p>(b) that the Website, Services and Competitions will be accurate, error-free, reliable, uninterrupted, that defects will be corrected, or that the Website, Services, Competitions or any related server are free of viruses or harmful components;</p>
        <p>&nbsp;</p>
        <p>(c) completeness, accuracy, availability, timeliness, security or reliability of the Website, Services, and Competitions;</p>
        <p>&nbsp;</p>
        <p>(d) the functionality of the Website, Services and Competitions as intended at times;</p>
        <p>&nbsp;</p>
        <p>(e) as to a minimum level of uptime for the Website, Services and Competitions; and/or</p>
        <p>&nbsp;</p>
        <p>(f) as to the results that may be obtained by you by accepting these Terms and/or using the Website and Services.</p>
        <p>&nbsp;</p>
        <p>9     .5. You agree and acknowledge that the Website is licensed and/or provided hereunder on an “as is” and “as available” basis, without any warranties of any kind, either express or implied including, but not limited to, the implied conditions and warranties of fitness for a particular purpose to the extent permitted by the Applicable Law.</p>
        <p>&nbsp;</p>
        <p>9     .6. While it is SWARMIO MEDIA INC’s objective to make the Website accessible at all times, the Website, Services, and/or Competitions may be unavailable from time to time for any reason including, without limitation, routine maintenance. In addition, various portions of the Website may operate slowly from time to time.</p>
        <p>&nbsp;</p>
        <p>9     .7. You understand and acknowledge that due to circumstances, within and outside the control of SWARMIO MEDIA INC, access to the Website, Services and/or Competitions may be interrupted, suspended or terminated from time to time. In particular, and not in limitation of the foregoing, SWARMIO MEDIA INC shall not be liable in any way for any delay in responding to an inquiry or question forwarded by you or the effects of any delay or unavailability may have on you and/or your Account.</p>
        <p>&nbsp;</p>
        <p>9     .8. You agree and acknowledge that SWARMIO MEDIA INC shall not be liable for any damages arising from any such interruption, suspension or termination of the Website, Services and/or Competitions and that you shall put in place contingency plans to account for such periodic interruptions or suspensions.</p>
        <p>&nbsp;</p>
        <p>9     .9. You shall not directly or indirectly copy, reproduce, sell, distribute, exploit, or use all or any part of the Website whether electronically, mechanically or otherwise, in any form for any purpose not expressly permitted by the provisions of these Terms.</p>
        <p>&nbsp;</p>
        <p>9     .10. You shall use the Website solely for its intended purposes and shall not use the Website and/or Services for the benefit of any third party.</p>
        <p>&nbsp;</p>
        <p>9     .11. You shall not violate or attempt to violate the security of the Website, Services and/or Competitions.</p>
        <p>&nbsp;</p>
        <p>9     .12. You shall not reverse engineer, decompile, disassemble or otherwise attempt to derive source code or other trade secrets from the Website, Services and/or Competitions including, without limitation, any of the software comprising or in any way making up a part of the Website. In addition, you will not export, re-export or permit any third party to export or re-export, directly or indirectly, the Website, Services, and/or Competitions where such export or re-export is prohibited by Applicable Law and these Terms without appropriate licenses and clearances from SWARMIO MEDIA INC.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>10     . Suspension &amp; Termination of Accounts</h1>
        <p>10     .1. SWARMIO MEDIA INC reserves the right, on notifying you, at any time, to immediately disable or delete your Account, terminate, cancel or suspend your access to the Website, Services, and/or any participation in Competitions, in addition to any other remedies it may have hereunder and/or at the Applicable Law, in its sole and absolute discretion, for any violation of any provisions of these Terms, and any of the following prohibited acts:</p>
        <p>&nbsp;</p>
        <p>(a) any fraud, deception, theft and/or misconduct caused by your use of Account, Services, Competitions on the Website;</p>
        <p>&nbsp;</p>
        <p>(b) the supply of false or misleading information to SWARMIO MEDIA INC;</p>
        <p>&nbsp;</p>
        <p>(c) abusive or offensive conduct towards any other Account holder and/or SWARMIO MEDIA INC’s staff, including spamming and unauthorized collection of other Account holder’s User Information;</p>
        <p>&nbsp;</p>
        <p>(d) collusion with others or engage in any type of syndicate play or use of single Account to participate in a Competition on behalf of others or any collaboration to effect the same;</p>
        <p>&nbsp;</p>
        <p>(e) any violation of Competition Rules;</p>
        <p>&nbsp;</p>
        <p>(f) use of automated/electronic means to interact with and/or abuse the Website, Services, and/or Competitions;</p>
        <p>&nbsp;</p>
        <p>(g) damage the Website, Services and/or Competitions in any way;</p>
        <p>&nbsp;</p>
        <p>(h) any type of abuse of offers and promotions offered by SWARMIO MEDIA INC;</p>
        <p>&nbsp;</p>
        <p>(i) tampering with the administration of a Competition in any way, and/or the security of the Website, Services, and any Competition;</p>
        <p>&nbsp;</p>
        <p>(j) any action that infringes or violates SWARMIO MEDIA INC’s or a third party’s intellectual property or other rights, and/or violates Applicable Law, and/or breaches any legal obligation towards SWARMIO MEDIA INC or any third party; and/or</p>
        <p>&nbsp;</p>
        <p>(k) uses or accesses the Website or Services from any jurisdictions in which such use or access is illegal or impermissible.</p>
        <p>&nbsp;</p>
        <p>In the event your Account is disabled or deleted by SWARMIO MEDIA INC due to a violation of these Terms, you agree and acknowledge that your right to Services, any Prize redemption and other benefits, or other items associated with the Account may be irrevocably lost and agree that you will release SWARMIO MEDIA INC of any liability of any nature in respect to the loss of the same.</p>
        <p>&nbsp;</p>
        <p>10     .2. SWARMIO MEDIA INC may immediately terminate or suspend your Account to protect you and others from identity theft or other fraudulent activity.</p>
        <p>&nbsp;</p>
        <p>10     .3. Access to the Website, end of Subscription and use of Service will terminate if:</p>
        <p>&nbsp;</p>
        <p>(a) you provide written or electronic notice of a request for your Account’s deactivation or submit to SWARMIO MEDIA INC any related forms to that affect accessible on your Account page and/or the Website in place by SWARMIO MEDIA INC, from time to time, the effects of which are the following:</p>
        <p>&nbsp;</p>
        <p>(i) SWARMIO MEDIA INC will contact you for information on the deactivation of your Account (Deactivation Support);</p>
        <p>&nbsp;</p>
        <p>(ii) your Account may be deactivated after 30 days, or any other period communicated by SWARMIO MEDIA INC to you, after receiving the Deactivation Support (the Deactivation Period);</p>
        <p>&nbsp;</p>
        <p>(iii) After the expiry of the Deactivation Period, you will no longer be entitled to access or use the Website, and Services and any active Subscriptions will be discontinued by SWARMIO MEDIA INC;</p>
        <p>&nbsp;</p>
        <p>(iv) You may re-activate your Account by logging in into your Account before the expiry of Deactivation Period, at such point in time your Account will be reactivated and in full functionality by SWARMIO MEDIA INC; and</p>
        <p>&nbsp;</p>
        <p>(v) Where Article 10.1(a)‎(iv) applies and upon the reactivation of your Account, the days your Account was under deactivation will be deducted from the days remaining from any active Subscriptions that you paid for;</p>
        <p>&nbsp;</p>
        <p>(b) SWARMIO MEDIA INC elects to immediately terminate your access to the Website, with or without notice, in accordance with the provisions of these Terms.</p>
        <p>&nbsp;</p>
        <p>10     .4. Subject to Article ‎‎17.6 and upon the deactivation of your Account, for any reason, your agreement with SWARMIO MEDIA INC under these Terms shall end. You shall no longer be entitled to access or use the Website and/or Services or any other non-public portions of the Website. In addition, you should redeem any Prizes or Points awarded before deactivating your Account or otherwise such Prizes or Points will be canceled and fortified.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>11     . Data Protection and Confidentiality</h1>
        <p>11     .1. SWARMIO MEDIA INC collects, uses and is responsible for certain personal information about you as further described herein. SWARMIO MEDIA INC, the details of which are listed in Articles ‎2.2 and ‎17.4(a), acts as a ‘Data Controller’ of personal information under these Terms in accordance with the provisions of Canadian law with respect to Personal Data Protection Law as may be amended from time to time (the Personal Data Protection Law).</p>
        <p>&nbsp;</p>
        <p>11     .2. For the purposes of this Article ‎11, Processing means the processing of User Information, whether or not by any automatic means, including collecting, recording, organizing, classifying into groups, storing, adapting, altering, retrieving, using, disclosing by transmission, dissemination, transference or otherwise making available for others, or combining, blocking, erasing or destructing such information.</p>
        <p>&nbsp;</p>
        <p>11     .3. SWARMIO MEDIA INC collects User Information related to you when you:</p>
        <p>&nbsp;</p>
        <p>(a) access the Website;</p>
        <p>&nbsp;</p>
        <p>(b) register an Account;</p>
        <p>&nbsp;</p>
        <p>(c) contact SWARMIO MEDIA INC and provide Feedback (as defined below);</p>
        <p>&nbsp;</p>
        <p>(d) purchase products and/or Services via the Website;</p>
        <p>&nbsp;</p>
        <p>(e) post material to the Website;</p>
        <p>&nbsp;</p>
        <p>(f) complete customer surveys; or</p>
        <p>&nbsp;</p>
        <p>(g) participate in Competitions.</p>
        <p>&nbsp;</p>
        <p>11     .4. SWARMIO MEDIA INC collects data related to you indirectly, such as your browsing activity while on the Website.</p>
        <p>&nbsp;</p>
        <p>11     .5. SWARMIO MEDIA INC collects User Information related to you, depending on the particular activity carried out on the Website. This User Information may include:</p>
        <p>&nbsp;</p>
        <p>(a) your name, address and contact details;</p>
        <p>&nbsp;</p>
        <p>(b) date of birth;</p>
        <p>&nbsp;</p>
        <p>(c) bank account and payment details;</p>
        <p>&nbsp;</p>
        <p>(d) details of any Feedback (as defined below) you give SWARMIO MEDIA INC by phone, email, post or via social media;</p>
        <p>&nbsp;</p>
        <p>(e) information about the Services SWARMIO MEDIA INC provides to you; and</p>
        <p>&nbsp;</p>
        <p>(f) your account details, such as username, login details.</p>
        <p>&nbsp;</p>
        <p>11     .6. SWARMIO MEDIA INC will use the User Information to:</p>
        <p>&nbsp;</p>
        <p>(a) create and manage your Account with SWARMIO MEDIA INC;</p>
        <p>&nbsp;</p>
        <p>(b) verify your identity;</p>
        <p>&nbsp;</p>
        <p>(c) provide goods and Services to you;</p>
        <p>&nbsp;</p>
        <p>(d) customize the Website and its content to your particular preferences;</p>
        <p>&nbsp;</p>
        <p>(e) send marketing material to you in accordance with Article ‎‎12;</p>
        <p>&nbsp;</p>
        <p>(f) notify you of any changes to the Website or Services that may affect you; and/or</p>
        <p>&nbsp;</p>
        <p>(g) improve Services.</p>
        <p>&nbsp;</p>
        <p>11     .7. Any Processing by SWARMIO MEDIA INC will be undertaken with your consent unless the Processing is necessary for:</p>
        <p>&nbsp;</p>
        <p>(a) the performance of these Terms;</p>
        <p>&nbsp;</p>
        <p>(b) taking steps at your request with the purpose of entering into these Terms;</p>
        <p>&nbsp;</p>
        <p>(c) the compliance with any legal obligation, other than an obligation imposed by contractual obligations, and/or the compliance with Applicable Law, orders issued by any competent court, and/or requests of law enforcement authorities;</p>
        <p>&nbsp;</p>
        <p>(d) protecting your vital interests or someone else’s life; and/or</p>
        <p>&nbsp;</p>
        <p>(e) pursuing the legitimate interests of SWARMIO MEDIA INC or any third party to whom the User Information has been disclosed to, in accordance with these Terms and/or Applicable Law, provided that it is not in conflict with your fundamental rights and freedoms.</p>
        <p>&nbsp;</p>
        <p>11     .8. SWARMIO MEDIA INC may transfer User Information outside of Canada in accordance with circumstances allowable under the Personal Data Protection Law.  In all cases, you hereby give your express consent to such transfer of data where deemed necessary or desirable by SWARMIO MEDIA INC.</p>
        <p>&nbsp;</p>
        <p>11     .9. SWARMIO MEDIA INC reserves the right to access, read, preserve, and disclose any information as SWARMIO MEDIA INC reasonably believe is necessary to:</p>
        <p>&nbsp;</p>
        <p>(a) satisfy any Applicable Law, legal process or governmental request;</p>
        <p>&nbsp;</p>
        <p>(b) enforce the Terms, including investigation of potential violations hereof;</p>
        <p>&nbsp;</p>
        <p>(c) detect, prevent, or otherwise address fraud, security or technical issues;</p>
        <p>&nbsp;</p>
        <p>(d) respond to Account holders support requests; and/or</p>
        <p>&nbsp;</p>
        <p>(e) protect the rights, property or safety of SWARMIO MEDIA INC, SWARMIO MEDIA INC Entities, its Account holders and the public.</p>
        <p>&nbsp;</p>
        <p>11     .10. Under the Personal Data Protection Law, you have a number of important rights which you may exercise, free of charge, which include the following:</p>
        <p>&nbsp;</p>
        <p>(a) fair Processing of information and transparency over how SWARMIO MEDIA INC use your personal information;</p>
        <p>&nbsp;</p>
        <p>(b) access to your personal information and to certain other supplementary information that these Terms are already designed to address;</p>
        <p>&nbsp;</p>
        <p>(c) require SWARMIO MEDIA INC to correct any mistakes in your information which SWARMIO MEDIA INC hold;</p>
        <p>&nbsp;</p>
        <p>(d) require the erasure, rectification or blocking of personal information concerning you that SWARMIO MEDIA INC is Processing in certain situations;</p>
        <p>&nbsp;</p>
        <p>(e) request of information on personal information concerning you that SWARMIO MEDIA INC is Processing, in an understandable format and the form stipulated in the Personal Data Protection Law;</p>
        <p>&nbsp;</p>
        <p>(f) object at any time to Processing of personal information concerning you for direct marketing;</p>
        <p>&nbsp;</p>
        <p>(g) object in certain other situations, stipulated by the Personal Data Protection Law, to SWARMIO MEDIA INC’s continued Processing of your personal information;</p>
        <p>&nbsp;</p>
        <p>(h) otherwise restrict our processing of your personal information in certain circumstances; and</p>
        <p>&nbsp;</p>
        <p>(i) lodge a complaint with the supervisory authority in accordance with the Personal Data Protection Law.</p>
        <p>&nbsp;</p>
        <p>11     .11. If you would like to exercise any of your rights listed in Article ‎11.10, please email SWARMIO MEDIA INC with the relevant information, action required and provide your contact details.</p>
        <p>&nbsp;</p>
        <p>11     .12. After the deactivation of your Account, SWARMIO MEDIA INC will ensure that any retained User Information associated with your Account is kept in a form which does not reveal your personal identity. Such retained User information will only be stored for a longer period for historical, statistical, or scientific use and shall be kept in anonymous form in accordance with the Personal Data Protection Law.</p>
        <p>&nbsp;</p>
        <p>11     .13. Throughout the Website, links to other websites owned and operated by certain third parties may (the Third Party Websites) exist. These Third Party Websites may also gather information about you in accordance with their own terms and conditions. For privacy information and/or the separate terms and conditions relating to the Third Party Website, please consult their terms and conditions and/or privacy policies as appropriate.</p>
        <p>&nbsp;</p>
        <p>11     .14. You agree to treat as confidential all confidential information of SWARMIO MEDIA INC, not to use such confidential information for any purpose other than to the limited extent necessary to use the Website and Services and not to disclose such confidential information to any third party except as may be reasonably required pursuant to these Terms and/or Applicable Law and subject to confidentiality obligations at least as protective as those set forth herein. Without limiting the generality of the foregoing, you shall use at least the same degree of care which other Account holders use to prevent the disclosure of the Account holder’s own confidential information of like importance to prevent the disclosure of confidential information disclosed by SWARMIO MEDIA INC, provided, however, that in no event shall such degree of care be less than reasonable in light of general industry practice.</p>
        <p>&nbsp;</p>
        <p>11     .15. SWARMIO MEDIA INC welcomes any feedback, comments, suggestions, raised concerns in relation to the use of your User Information and/or any other matter (the Feedback) that you might have on the Website and Services provided.</p>
        <p>&nbsp;</p>
        <p>11     .16. Feedback you may provide regarding SWARMIO MEDIA INC, and/or the Services is entirely voluntary and SWARMIO MEDIA INC will be free to use such Feedback as SWARMIO MEDIA INC see fit and without any obligation to you.</p>
        <p>&nbsp;</p>
        <p>11     .17. SWARMIO MEDIA INC shall not be subject to any obligation of confidentiality in relation to any submitted Feedback.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>12     . Marketing</h1>
        <p>12     .1. SWARMIO MEDIA INC would like to send you information regarding future Competitions and any additional features added to the Website, Services, special offers and Prizes which may be of interest to you. By agreeing to these Terms, you hereby give your consent to being sent such information by SWARMIO MEDIA INC to you by post, email, telephone, text message (SMS) or automated call.</p>
        <p>&nbsp;</p>
        <p>12     .2. If you have previously agreed to being contacted in this way, you can unsubscribe at any time by:</p>
        <p>&nbsp;</p>
        <p>(a) using the ‘unsubscribe’ links in emails or ‘STOP’ number in texts or any other forms or procedure to effect the same, in place by SWARMIO MEDIA INC from time to time; or</p>
        <p>&nbsp;</p>
        <p>(b) updating your marketing preferences on our Website;</p>
        <p>&nbsp;</p>
        <p>12     .3. It may take up to 10 working days for the withdrawal of the marketing subscription (as set out in Article ‎‎12.2) to take place.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>13     . Access and Conduct</h1>
        <p>13     .1. You agree not to use the Services or Websites to:</p>
        <p>&nbsp;</p>
        <p>(a) obtain or attempt to obtain unauthorized access to the Services, Website, and/or Competitions;</p>
        <p>&nbsp;</p>
        <p>(b) make available any content that is harmful to Minors and/or other Account holders, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, inaccurate, unlawful, offensive, hateful, discriminatory or otherwise objectionable;</p>
        <p>&nbsp;</p>
        <p>(c) violate any Applicable Laws;</p>
        <p>&nbsp;</p>
        <p>(d) falsely impersonate any person or entity;</p>
        <p>&nbsp;</p>
        <p>(e) forge or manipulate headers or identifiers to disguise the origin of any content transmitted on the Website, through the Services and/or any email or posting to send altered, deceptive, or false source-identifying information;</p>
        <p>&nbsp;</p>
        <p>(f) make available any content that you do not have the right to make available or that infringes any patent, trademark, trade secrets, copyright or other proprietary rights of any person or entity;</p>
        <p>&nbsp;</p>
        <p>(g) post content containing advertisements or other commercial solicitations without SWARMIO MEDIA INC’s prior written permission;</p>
        <p>&nbsp;</p>
        <p>(h) spam, flood, overload and/or make available viruses, trojan horses, worms, time bombs, cancel bots or any other computer code, files, programs or content designated to interrupt, detrimentally interfere with, damage, destroy or limit the functionality of the Website, Services, and/or Competitions or affect other Account holders, User Information, and/or User Content (as defined below); and/or</p>
        <p>&nbsp;</p>
        <p>(i) interfere with, disrupt, and/or tamper with the Website, Services, server, systems or networks connected to the Websites or Services in any way.</p>
        <p>&nbsp;</p>
        <p>13     .2.You shall not use the Website to post, transmit, convey, submit, distribute, store or destroy any content, photographs, descriptions, drawings, content, audio materials, text, messages or other information:</p>
        <p>&nbsp;</p>
        <p>(a) in violation of any Applicable Law;</p>
        <p>&nbsp;</p>
        <p>(b) in a manner that will infringe the intellectual property rights of SWARMIO MEDIA INC. or others;</p>
        <p>&nbsp;</p>
        <p>(c) that is defamatory, obscene or libelous;</p>
        <p>&nbsp;</p>
        <p>(d) that contains any viruses, Trojan horses, worms, time bombs, cancel bots or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or User Information;</p>
        <p>&nbsp;</p>
        <p>(e) that is false, misleading or inaccurate in any way; and/or</p>
        <p>&nbsp;</p>
        <p>(f) in violation of any acceptable use policy or other policies posted by SWARMIO MEDIA INC on its Website from time to time.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>14     . Content on the Website and Services</h1>
        <p>14     .1. The Website may allow you to post, share, exchange information or content, including but not limited to, images, texts, video, audios and the like, and other material (the User Content). You are responsible for your use, sharing, and provision of User Content, including compliance with the Applicable Law.</p>
        <p>&nbsp;</p>
        <p>14     .2. SWARMIO MEDIA INC does not screen, edit, or review such User Content before it is posted or transmitted. You should only provide User Content that you are comfortable with.</p>
        <p>&nbsp;</p>
        <p>14     .3. Any use or reliance on any User Content posted on the Website or obtained through the Services is at your risk.</p>
        <p>&nbsp;</p>
        <p>14     .4. All User Content is the sole responsibility of the Account holder, entity, person, third party who originated such content.</p>
        <p>&nbsp;</p>
        <p>14     .5. SWARMIO MEDIA INC reserves the right, in its sole discretion, to remove User Content that violates these Terms and the Applicable Law including but not limited to, copyright or trademark violations or other intellectual property misappropriation, impersonation, unlawful conduct, or harassment.</p>
        <p>&nbsp;</p>
        <p>14     .6. By posting or transmitting User Content on the Website, you grant SWARMIO MEDIA INC a worldwide, non-exclusive, perpetual, irrevocable, fully-paid up, royalty-free, assignable, transferable, and sub-licensable, license to exploit, use, access, store, reproduce, adapt, translate, publish, publicly perform, publicly display, modify, repost, sublicense, transmit, create derivative works from, and distribute your User Content in any and all media or distribution methods now known or later developed, subject to these Terms, without any notice or compensation to you or any other person (the User Content License).</p>
        <p>&nbsp;</p>
        <p>14     .7. You agree that the User Content License includes the right for SWARMIO MEDIA INC to provide, promote, and improve the Services and to make User Content posted on the Website or submitted to or through the Services available to other companies, organizations or individuals for the syndication, broadcast, distribution, promotion or publication of such User Content on other media and services, subject to these Terms.</p>
        <p>&nbsp;</p>
        <p>14     .8. You confirm, represent, and warrant to SWARMIO MEDIA INC that you have all the rights, power, consents, permissions and authority necessary to grant the User Content License and that any User Content you submit to SWARMIO MEDIA INC or otherwise post on the Website is and shall be your own original work or work which you are authorized to supply to SWARMIO MEDIA INC.</p>
        <p>&nbsp;</p>
        <p>14     .9. SWARMIO MEDIA INC retains the right to create limits on use and storage at SWARMIO MEDIA INC’s sole discretion at any time. SWARMIO MEDIA INC may also remove or refuse to distribute any User Content on the Services, limit distribution or visibility of any User Content on the Website and/or Service, immediately suspend or terminate Users Accounts, and reclaim usernames without liability to you.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>15     . Intellectual Property</h1>
        <p>15     .1. Notwithstanding any terms to the contrary, you expressly acknowledge and agree that you have no right, title, and/or ownership interest in any Account, and your Account is not your property.</p>
        <p>&nbsp;</p>
        <p>15     .2. SWARMIO MEDIA INC gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you as part of the Services and Website. This license has the sole purpose of enabling you to use and enjoy the benefit of the Services provided by SWARMIO MEDIA INC, in the manner permitted by these Terms.</p>
        <p>&nbsp;</p>
        <p>15     .3. The content on the Website, including without limitation to, texts, software, code, scripts, graphics, rights in get-ups, typographic typefaces, graphic user interface, photos, collages, sounds, music, audio, videos, interactive content, and the like, online databases, copyright, patents, related rights and moral rights, trademarks, trade names, product names, service marks, logos, rights in domain names, social media handles, rights in design, rights in information and all other or equivalent rights subsisting now or in future in any part of the world in each case, whether registered or unregistered, and including all applications (the Intellectual Property), are owned by or licensed to SWARMIO MEDIA INC. The content on the Website is provided to you “AS IS” for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of SWARMIO MEDIA INC.</p>
        <p>&nbsp;</p>
        <p>15     .4. Nothing in the Terms gives you the right to use SWARMIO MEDIA INC’s Intellectual Property other than to permit you to use the Services. All rights and interest in the Services (excluding others’ User Content) are and will remain the exclusive property of SWARMIO MEDIA INC and its licensors.</p>
        <p>&nbsp;</p>
        <p>15     .5. SWARMIO MEDIA INC reserves all rights, interests, titles not expressly granted in and to the Website and/or Services and the Intellectual Property.</p>
        <p>&nbsp;</p>
        <p>15     .6. You may download or print a hard copy of materials from the Website for your own personal use, as explicitly authorized by these Terms, but any copying or reproduction of these materials for commercial purposes without the permission of SWARMIO MEDIA INC is prohibited.</p>
        <p>&nbsp;</p>
        <p>15     .7. If you download or print a copy of any materials from the Website for personal use, in accordance with Article ‎‎15.6, you must retain all copyright and other proprietary notices contained therein. You agree not to circumvent, disable or otherwise interfere with security related features of the Website or features that prevent or restrict use or copying of any Intellectual Property or enforce limitations on use of the Website or the Intellectual Property therein.</p>
        <p>&nbsp;</p>
        <p>15     .8. The distribution, re-transmission, republication, modification, reverse engineering, sale or other exploitation of the Website or any of the Services without the permission of SWARMIO MEDIA INC is prohibited.</p>
        <p>&nbsp;</p>
        <p>15     .9. All Intellectual Property rights, title and interest owned by SWARMIO MEDIA INC and/or yourself on the date hereof shall continue to be owned solely by each party, and except as set forth herein, nothing in these Terms shall be deemed to confer any ownership rights to any such Intellectual Property on the other.</p>
        <p>&nbsp;</p>
        <p>15     .10. You acknowledge and agree that all worldwide rights, title and interest in any third party software, products and/or services (and any Intellectual Property rights therein) accessible on the Website or through the Services, subject to third party licenses, if any, are owned by such third parties.</p>
        <p>&nbsp;</p>
        <p>15     .11. If you provide Feedback, in accordance with Article ‎11.15, you grant SWARMIO MEDIA INC a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, transferable, and fully sub-licensable right to use, reproduce, publish, distribute, publicly display, publicly perform, translate, adapt, modify, telecommunicate, rent out, commercialize, monetize, and create derivative works from the Feedback in any way and for any purpose without providing any compensation to you or any other person. You also grant SWARMIO MEDIA INC the right to use the name you submit with the Feedback, if any, in connection with SWARMIO MEDIA INC’s rights hereunder.</p>
        <p>&nbsp;</p>
        <p>15     .12. You represent, warrant, and covenant to SWARMIO MEDIA INC that your Feedback does not violate the privacy rights, intellectual property rights, personality rights, or any other rights, of any person and/or third party.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>16     . Limitation of Liability</h1>
        <p>16     .1. SWARMIO MEDIA INC and any SWARMIO MEDIA INC Entities shall not, under any set of circumstances, be liable to you for any damage, loss to persons and/or property to the extent permitted by the Applicable Law, special, incidental, indirect, punitive or consequential damages, including, but not limited to, lost profits or data, whether based in contract, negligence, tort, Applicable Law, even if advised of the possibility of such damages to the extent permitted by the Applicable Law, arising directly or indirectly, out of:</p>
        <p>&nbsp;</p>
        <p>(a) acceptance or breach the provisions of these Terms by you or any other third party;</p>
        <p>&nbsp;</p>
        <p>(b) your use or attempted use of, or inability to use, the Website and/or Services;</p>
        <p>&nbsp;</p>
        <p>(c) participation in a Competition and acceptance of the Competition Rules;</p>
        <p>&nbsp;</p>
        <p>(d) any errors or omission in the Website and Services’ technical operations;</p>
        <p>&nbsp;</p>
        <p>(e) human errors and inaccurate or incorrect entry of information;</p>
        <p>&nbsp;</p>
        <p>(f) postage delay of and delivery of due mail and Prizes;</p>
        <p>&nbsp;</p>
        <p>(g) any User Content, third party content or conduct on the Services and Website;</p>
        <p>&nbsp;</p>
        <p>(h) any unauthorized access, use or alteration of your User Content;</p>
        <p>&nbsp;</p>
        <p>(i) omissions, interruptions, deletions or defects of any computer online systems, data, computer equipment, servers, providers, or software resulting from the accessing and/or using the Website, Services and/or participation in Competitions;</p>
        <p>&nbsp;</p>
        <p>(j) theft, tampering, destruction, or unauthorized access to, or alteration of, entries and/or images of any kind on the Website, Services, and/or Competitions;</p>
        <p>&nbsp;</p>
        <p>(k) computer or electronic malfunction or traffic congestion on telephone lines or transmission systems, or the Internet, or any service provider’s facilities, or any phone site or website or for any other reason whatsoever related to the use of Website, Services, and participation in Competitions; and/or</p>
        <p>&nbsp;</p>
        <p>(l) any action in connection with investigation by SWARMIO MEDIA INC or law enforcement authorities in relation to use of the Website and/or Services.</p>
        <p>&nbsp;</p>
        <p>1     6.2. You shall defend and indemnify SWARMIO MEDIA INC, at your sole cost and expense, from and against any claims, damages, liabilities and/or expenses made against SWARMIO MEDIA INC and/or any SWARMIO MEDIA INC Entities arising out of:</p>
        <p>&nbsp;</p>
        <p>(a) any breach of your obligations, representations, and/or warranties set forth in these Terms and/or Competition Rules;</p>
        <p>&nbsp;</p>
        <p>(b) any claims based on publicity rights, defamation, or invasion of privacy;</p>
        <p>&nbsp;</p>
        <p>(c) any of your acts that may constitute a violation or infringement of a third party’s proprietary or intellectual property rights;</p>
        <p>&nbsp;</p>
        <p>(d) any use or attempted used of the Website and its Services or failure to take appropriate action where relevant; and/or</p>
        <p>&nbsp;</p>
        <p>(e) your participation and conduct in Competitions and while using the Services and Website.</p>
        <p>&nbsp;</p>
        <p>16     .3. In addition, you hereby agree and acknowledge that:</p>
        <p>&nbsp;</p>
        <p>(a) SWARMIO MEDIA INC shall not be responsible for any actions taken by any third party using the Website including but not limited to, Account holder’ actions or conduct, online or offline, or any User Content they share, including but not limited to offensive, inappropriate, obscene, mislabelled, inaccurate, deceptive, unlawful, and other objectionable conduct and shared content;</p>
        <p>&nbsp;</p>
        <p>(b) when you click on Third Party Websites, you will leave the Website and SWARMIO MEDIA INC has no control over such Third Party Websites;</p>
        <p>&nbsp;</p>
        <p>(c) SWARMIO MEDIA INC does not assume any responsibility or liability over Third Party Websites, their contents, services, material, and/or products advertised, offered, made available or performed, which remain in the sole responsibility of the owner and/or operator of such Third Party Websites;</p>
        <p>&nbsp;</p>
        <p>(d) the terms and conditions and privacy policies and provisions of Third Party Websites may be different from those applicable under these Terms;</p>
        <p>&nbsp;</p>
        <p>(e) SWARMIO MEDIA INC has no control over and does not recommend or endorse any Third Party Websites and others’ User Content, and that SWARMIO MEDIA INC does not makes any representations, warranties, guarantees as the completeness, truthfulness, security, accuracy or reliability of whatsoever regarding any such Third Party Website and others’ User Content or opinions or supports and endorses the same;</p>
        <p>&nbsp;</p>
        <p>(f) your use of Third Party Websites and others’ User Content or any material provided and/or posted on the Website by third parties other than SWARMIO MEDIA INC is at your own risk and you should make any investigation you feel necessary before proceeding with any transaction in connection with any third party connected and/or accessible on SWARMIO MEDIA INC’s Website and/or Services ;</p>
        <p>&nbsp;</p>
        <p>(g) SWARMIO MEDIA INC is not a party to any transaction between you and any third party, supplier, or business with which SWARMIO MEDIA INC has a business relationship, and as such, any disputes regarding purchases of products, services, software, and/or access to any Third Party Websites, receipt of Prizes supplied by the mentioned entities and/or any other aspect of any transaction or other commercial dealings is solely between you and such third party;</p>
        <p>&nbsp;</p>
        <p>(h) SWARMIO MEDIA INC is not responsible for any other party’s compliance with the Applicable Laws.</p>
        <p>&nbsp;</p>
        <p>(i) SWARMIO MEDIA INC shall not, under any set of circumstances, be responsible or liable for User Content posted by you, which may violate the Applicable Law and/or a third party’s intellectual property rights.</p>
        <p>&nbsp;</p>
        <p>16     .4. To the extent permitted by Applicable Law, SWARMIO MEDIA INC is not responsible for losses that were not foreseeable to you and SWARMIO MEDIA INC when these Terms were entered into.</p>
        <p>&nbsp;</p>
        <p>16     .5. SWARMIO MEDIA INC shall not be liable to you for failure or delay in performing any obligations hereunder if such failure or delay is due to circumstances beyond its reasonable control.</p>
        <p>&nbsp;</p>
        <p>16     .6. In no event shall SWARMIO MEDIA INC and/or SWARMIO MEDIA INC Entities, be held liable for damages, loss, or cause of action that exceeds the total Subscription Fees paid by you to SWARMIO MEDIA INC for continued access and use of the Website and/or Services.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>17. General Provisions</h1>
        <p>17.1. Any reference in Terms to ‘you’, ‘your’ or ‘yourself’ means the Account holder accessing SWARMIO MEDIA INC’s Website, registering an Account, using Services, and reading, understanding, acknowledging these Terms or (as applicable) a Legal Guardian as referenced at Article 3.1(a).</p>
        <p>&nbsp;</p>
        <p>17.2. Headings inserted in these Terms are for convenience only and shall not affect the interpretation of the provisions of the Terms.</p>
        <p>&nbsp;</p>
        <p>17.3. Except where explicitly provided otherwise herein, any notice required or permitted hereunder will be delivered to SWARMIO MEDIA INC, at the relevant address set out in Article ‎‎17     .4, as follows (with notice deemed given as indicated): (i) by personal delivery when delivered personally; (ii) by established overnight courier upon written verification of receipt; (iii) by certified or registered mail, return receipt requested, upon verification of receipt; or (iv) by electronic delivery when receipt is confirmed.</p>
        <p>&nbsp;</p>
        <p>17.4. Notices to be sent to SWARMIO MEDIA INC shall be at the following addresses (where applicable):</p>
        <p>&nbsp;</p>
        <p>(a) Physical notices: 1809 Barrington Street, Suite 505, Halifax, NS. Canada B3J 3K8</p>
        <p>&nbsp;</p>
        <p>(b) Email notices: <a href="mailto:info@ember.gg">info@ember.gg</a></p>
        <p>&nbsp;</p>
        <p>17.5. SWARMIO MEDIA INC may give effective notice, as required hereunder, to you by: (i) email; (ii) by certified or registered mail, return receipt requested, upon verification of receipt; (iii) SMS to the applicable address or number you provide on your Account; or (iv) notice posted on SWARMIO MEDIA INC’s Website.</p>
        <p>&nbsp;</p>
        <p>17.6. To fulfill their purpose, the following provisions will survive the termination of your Account and the end of this binding agreement between the Parties under these Terms:</p>
        <p>&nbsp;</p>
        <p>(a) any warranties, representations, and acknowledgements you provide under these Terms, particularly in Articles ‎3.1, ‎3.2,‎ 9.4, ‎9.5, ‎9.7; and</p>
        <p>&nbsp;</p>
        <p>(b) Articles ‎11, ‎‎14 to ‎17.</p>
        <p>&nbsp;</p>
        <p>17.7. Account holders may not, without the prior written consent of SWARMIO MEDIA INC, assign the provisions of these Terms, in whole or in part, either voluntarily or by operation of Applicable Law, and any attempt to do so will be a material default of these Terms and such assignment will be void. SWARMIO MEDIA INC may assign the provisions of these Terms in part or in their entirety, including our rights, interests, and obligations hereunder, without notice to you or your consent.</p>
        <p>&nbsp;</p>
        <p>17.8.The provisions of these Terms are solely for the benefit of the Parties and their successors and permitted assigns, and does not confer any rights or remedies on any other person or entity.</p>
        <p>&nbsp;</p>
        <p>17.9. The failure of either Party to exercise or enforce any of its rights under these Terms will not act as a waiver of subsequent breaches and the waiver of any breach will not act as a waiver of subsequent breaches.</p>
        <p>&nbsp;</p>
        <p>17.10. If any provision of these Terms held by a court or other tribunal of competent jurisdiction to be unenforceable, that provision will be enforced to the maximum extent permissible under by the Applicable Law and the other provisions of the Terms will remain in full force and effect.</p>
        <p>&nbsp;</p>
        <p>17.11. You agree to provide such further documents or instruments, and take such further actions, reasonably requested by SWARMIO MEDIA INC, to effect the purposes of these Terms and carry out its provisions.</p>
        <p>&nbsp;</p>
        <p>17.12. Neither party shall be liable to the other if such Party is prevented from performing any of its obligations under the Terms (excluding fee payment obligations) due to any cause beyond the Party’s reasonable control including, without limitation, an act of God, fire, flood, explosion, pandemics, epidemics, terrorism, war, embargo, government regulation, civil or military authority, acts or omissions of carriers, transmitters, providers, or acts of vandals, or hackers. The time for that party’s performance will be extended for the period of the delay or failure to perform due to such occurrence, except that you will not be excused from the payment of any sums of money owed by you to SWARMIO MEDIA INC provided prior to the force majeure event.</p>
        <p>&nbsp;</p>
        <p>17.13. These Terms constitute the entire agreement between SWARMIO MEDIA INC and yourself with respect to the subject matter hereof and all prior oral or written agreements, representations or statements with respect to such subject matter are superseded hereby.</p>
        <p>&nbsp;</p>
        <p>17.14. Any matter, claim or dispute, whether contractual or non-contractual, arising out of or in connection with these Terms, which cannot be settled amicably between the Parties, shall be subject to the exclusive jurisdiction of the courts of Canada, and you consent to such exclusive jurisdiction and waive any objection as to inconvenient forum.</p>
    </div>
</Container>
)

export default TermsAndConditions