import { useTranslation } from 'react-i18next';
import { Props } from 'HiveClient/components/FooterContent/LearnLinks';

const LearnLinks = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();

  return (
    <nav className={props.className}>
      <h6>{t('footer.learn-more.heading')}</h6>
      <a
        href={'https://globegg.emberhub.gg/terms-conditions/'}
        data-semantic={'footer-tos-link'}
      >
        {t('footer.learn-more.tos-link-label')}
      </a>
      <a
        href={'https://globe.emberhub.gg'}
        target='blank'
        data-semantic={'footer-learn-more-link'}
      >
        {t('footer.learn-more.about-link-label')}
      </a>
    </nav>
  );
};

export default LearnLinks;
