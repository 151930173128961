import { useTranslation } from 'react-i18next';
import { Props } from 'HiveClient/components/FooterContent/ContactLinks';

const ContactLinks = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();

  return (
    <nav className={props.className}>
      <h6>{t('footer.contact-us.heading')}</h6>
      <a
        href={'mailto:info@emberhub.gg'}
        target='blank'
        data-semantic={'footer-buisness-inquiries-link'}
      >
        {t('footer.contact-us.business-inquiries-link-label')}
      </a>
      <a
        href={`https://help.gamergrounds.ph`}
        target='blank'
        data-semantic={'footer-help-center-link'}
      >
        {t('footer.contact-us.help-center-link-label')}
      </a>
    </nav>
  );
};

export default ContactLinks;
